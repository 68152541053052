.page {
  height: 100vh;
  overflow-x: scroll;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

body {
  background: #0b0b0b;
  color: white;
}

p {
  text-align: justify;
}

.appContainer {
  border-left: 0.5rem solid #ffcc51;
}

/* this is to stop the gradient overflowing on to yellow border */
.ui.secondary.inverted.menu {
  background-color: rgba(11, 11, 11, 0.8);
  margin-left: 0rem;
}
